import React, { useEffect } from 'react'
import * as s from "./main.module.css"

import { gsap } from "gsap/dist/gsap";

import Icon from '../assets/article.svg';
import Quotes from '../assets/quotes.svg';

import { withpath } from '../utils/index.js'

import { Waypoint } from 'react-waypoint';

const Main = (p) => {

	const data = p.data
  const waypercent = "20%"
  let vdistance = 30
  let vdistancequote = 10
  let hdistancequote = 0

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});

	useEffect(() => {
    
    let sbox1 = document.querySelectorAll(`#shadedbox1`);
    let sbox1c = document.querySelectorAll(`#shadedbox1 div`);

    gsap.killTweensOf([sbox1,sbox1c])
		gsap.set(sbox1, {opacity: 0, translateY:vdistance, scale: .96})
    gsap.set(sbox1c, {opacity: 0, translateY:vdistance, scale: .96, transformOrigin: "50% 50%"})
    

    let sbox2 = document.querySelectorAll(`#shadedbox2`);
    let sbox2c = document.querySelectorAll(`#shadedbox2 div`);

    gsap.killTweensOf([sbox2,sbox2c])
		gsap.set(sbox2, {opacity: 0, translateY:vdistance, scale: .96})
    gsap.set(sbox2c, {opacity: 0, translateY:vdistancequote, scale: .9, transformOrigin: "50% 50%"})
    

    let quote1 = document.querySelectorAll(`#quote1 div`);
    gsap.killTweensOf([quote1])
    gsap.set(quote1, {opacity: 0, translateX: hdistancequote, translateY:vdistancequote, scale: .9, transformOrigin: "50% 50%"})


    let quote2 = document.querySelectorAll(`#quote2 div`);
    gsap.killTweensOf([quote2])
    gsap.set(quote2, {opacity: 0, translateX: hdistancequote, translateY:vdistancequote, scale: .9, transformOrigin: "50% 50%"})

  },[])


  
	function enter0(){

    let sbox1 = document.querySelectorAll(`#shadedbox1`);
    let sbox1c = document.querySelectorAll(`#shadedbox1 div`);

    gsap.killTweensOf([sbox1,sbox1c])
		gsap.set(sbox1, {opacity: 0, translateY:vdistance, scale: .96})
		gsap.set(sbox1c, {opacity: 0, translateY:vdistance, scale: .96, transformOrigin: "50% 50%%"})
    
    let tl = gsap.timeline({ paused: false });
			tl.to(sbox1, {opacity: 1, translateY: 0, duration: .7, ease: `power1.out` })
			tl.to(sbox1c, {opacity: 1, translateY: 0, scale: 1, duration: .7, stagger: 0.2, ease: `power1.out`}, '<.2')
	}

	function leave0(){

    let sbox1 = document.querySelectorAll(`#shadedbox1`);
    let sbox1c = document.querySelectorAll(`#shadedbox1 div`);

    gsap.killTweensOf([sbox1,sbox1c])
    
    let tl = gsap.timeline({ paused: false });
			tl.to(sbox1, {opacity: 0, translateY: vdistance, duration: .4, ease: `power1.out` })
			tl.to(sbox1c, {opacity: 0, translateY: vdistance, scale: .96, duration: .5, stagger: 0.1, ease: `power1.out`}, '<.1')

	}


	function enter1(){

    let sbox2 = document.querySelectorAll(`#shadedbox2`);
    let sbox2c = document.querySelectorAll(`#shadedbox2 div`);

    gsap.killTweensOf([sbox2,sbox2c])
		gsap.set(sbox2, {opacity: 0, translateY:vdistance, scale: .96})
    gsap.set(sbox2c, {opacity: 0, translateY:vdistance, scale: .96, transformOrigin: "50% 50%"})
    
    let tl = gsap.timeline({ paused: false });
			tl.to(sbox2, {opacity: 1, translateY: 0, duration: .7, ease: `power1.out` })
			tl.to(sbox2c, {opacity: 1, translateY: 0, scale: 1, duration: .7, stagger: 0.2, ease: `power1.out`}, '<.2')
		
	}

	function leave1(){
		let sbox2 = document.querySelectorAll(`#shadedbox2`);
    let sbox2c = document.querySelectorAll(`#shadedbox2 div`);

    gsap.killTweensOf([sbox2,sbox2c])
    
    let tl = gsap.timeline({ paused: false });
			tl.to(sbox2, {opacity: 0, translateY: vdistance, duration: .4, ease: `power1.out` })
			tl.to(sbox2c, {opacity: 0, translateY: vdistance, scale: .96, duration: .5, stagger: 0.1, ease: `power1.out`}, '<.1')
  }


	function enterq1(){

    let quote1 = document.querySelectorAll(`#quote1 div`);
    gsap.killTweensOf([quote1])
    gsap.set(quote1, {opacity: 0, translateX: hdistancequote, translateY:vdistancequote, scale: 1, transformOrigin: "50% 50%"})

    let tl = gsap.timeline({ paused: false });
			tl.to(quote1, {opacity: 1, translateX: 0, translateY: 0, duration: .7, stagger: 0.1, ease: `power1.out`})
      
	}

	function leaveq1(){
    let quote1 = document.querySelectorAll(`#quote1 div`);
    gsap.killTweensOf([quote1])
    gsap.set(quote1, {opacity: 0, translateX: hdistancequote, translateY:vdistancequote, scale: .9, transformOrigin: "50% 50%"})

    let tl = gsap.timeline({ paused: false });
			tl.to(quote1, {opacity: 0, translateX: hdistancequote, translateY: vdistancequote, duration: .4, stagger: 0.05, ease: `power1.out`})
	}

  
  function enterq2(){

    let quote2 = document.querySelectorAll(`#quote2 div`);
    gsap.killTweensOf([quote2])
    gsap.set(quote2, {opacity: 0, translateX: hdistancequote, translateY:vdistancequote, scale: 1, transformOrigin: "50% 50%"})

    let tl = gsap.timeline({ paused: false });
			tl.to(quote2, {opacity: 1, translateX: 0, translateY: 0, duration: .7, stagger: 0.1, ease: `power1.out`})
      
	}

	function leaveq2(){
    let quote2 = document.querySelectorAll(`#quote2 div`);
    gsap.killTweensOf([quote2])
    gsap.set(quote2, {opacity: 0, translateX: hdistancequote, translateY:vdistancequote, scale: .9, transformOrigin: "50% 50%"})

    let tl = gsap.timeline({ paused: false });
			tl.to(quote2, {opacity: 0, translateX: hdistancequote, translateY: vdistancequote, duration: .4, stagger: 0.05, ease: `power1.out`})
	}
  


	return (
		<section className={s.article} >
      
      {/* Hero Block */}
			<div 
				className={s.headerbg} 
				style={{backgroundImage: `url(${withpath(`/img/bg.jpg`)})` }}
			>
				<div className={s.svgbg}
					style={{backgroundImage: `url(${withpath(`/img/cover.svg`)})` }}
				/>
				<div className={s.headerbox}>
					<div className={s.hicon}>
						<Icon />
					</div>
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
					<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
				</div>
			</div>



      {/* Intro */}
			<div className={s.box} data-id={0}>

				<div className={s.tbox} data-id={0}>
					<div className={s.tboxl} data-id={0}>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={4} />
          
					</div>
					<div className={s.tboxr} data-id={0}>

            <Waypoint 
                onEnter={(p) => {enterq1()}} 
                onLeave={(p) => {leaveq1()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={`${s.quotesbox} ${s.quotesboxAlt}`}>
                <div id='quote1' className={`${s.quotestxt} ${s.quotestxtAlt}`}>
                  {data.quotes[0].txt.split(' ').map((words, i) => {
                    return <div key={i}>{words}{ }</div> ;
                  })}
                </div>
              </div>
            </Waypoint>

					</div>
				</div>


        {/* International experience */}
				<div className={s.tbox} data-id={1}>

					<div className={s.tboxl} data-id={1}>
            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={0} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={5} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={6} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[8] }} data-id={7} />
					</div>
					<div className={s.tboxr} data-id={1}>
            <Waypoint 
							onEnter={(p) => {enter0()}} 
							onLeave={(p) => {leave0()}} 
							bottomOffset={waypercent} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
            <div id="shadedbox1" className={s.shadedbox}>
              <div className={`${s.htxt} ${s.htxtBox}`} dangerouslySetInnerHTML={{__html: data.txt[9] }} data-id={8} />
              <div className={s.hr} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[10] }} data-id={9} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[11] }} data-id={10} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[12] }} data-id={11} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[13] }} data-id={12} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[14] }} data-id={13} />
            </div>
            </Waypoint>
					</div>
				</div>

			</div>

      {/* Digitalisation and diversification */}
			<div className={s.box} data-id={1}>

				<div className={s.tbox} data-id={2}>
					<div className={s.tboxl} data-id={2}>
            <Waypoint 
                onEnter={(p) => {enterq2()}} 
                onLeave={(p) => {leaveq2()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={`${s.quotesbox} ${s.quotesboxAlt}`}>
                <div id='quote2' className={`${s.quotestxt} ${s.quotestxtAlt}`}>
                  {data.quotes[1].txt.split(' ').map((words, i) => {
                  return <div key={i}>{words}{ }</div> ;
                  })}
                </div>
              </div>
            </Waypoint>
					</div>
					<div className={s.tboxr} data-id={2}>
            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[15] }} data-id={14} />
						<div className={s.hr} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[16] }} data-id={15} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[17] }} data-id={16} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[18] }} data-id={17} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[19] }} data-id={18} />
					</div>
				</div>
			</div>


      {/* Social commitment */}
			<div className={s.box} data-id={3}>
				<div className={s.tbox} data-id={4}>
					<div className={s.tboxl} data-id={4}>
            <div className={`${s.multiart} ${s.multiartDesktop}`}>
              <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[20] }} data-id={19} />
              <div className={s.hr} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[21] }} data-id={20} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[22] }} data-id={21} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[23] }} data-id={22} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[24] }} data-id={23} />
              <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[25] }} data-id={24} />
            </div>
					</div>
					<div className={s.tboxr} data-id={4}>
            <Waypoint 
							onEnter={(p) => {enter1()}} 
							onLeave={(p) => {leave1()}} 
							bottomOffset={waypercent} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
              <div id="shadedbox2" className={s.shadedbox}>
                <div className={s.quotesbox}>
                  <div className={s.quotes}>
                    <Quotes />
                  </div>
                  <div className={s.quotestxt} dangerouslySetInnerHTML={{__html: data.quotes[2].txt[0] }} />
                  <div className={s.quotestxt} dangerouslySetInnerHTML={{__html: data.quotes[2].txt[1] }} />
                  <div className={s.quoteshr}></div>
                  <div className={s.person}>
                    <img id="person1-img" src={withpath(`/img/honora_ducatillon.png`)} alt="Honora Ducatillon portrait" />
                  </div>
                  <div className={s.quotesname} dangerouslySetInnerHTML={{__html: data.quotes[2].name }} />
                </div>
              </div>
            </Waypoint>
					</div>
				</div>
			</div>

{/*
			<div className={s.box} data-id={4}>
				<div className={s.tbox} data-id={5}>
					<div className={s.tboxl} data-id={5}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[25] }} data-id={25} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[26] }} data-id={26} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[27] }} data-id={27} />
					</div>
					<div className={s.tboxr} data-id={5}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[28] }} data-id={28} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[29] }} data-id={29} />
					</div>
				</div>


				<div className={s.tbox} data-id={6}>
					<div className={s.sources} dangerouslySetInnerHTML={{__html: `Sources:`}} />
					<a href={data.sources[0]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `1. ` + data.sources[0] }} />
					<a href={data.sources[1]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `2. ` + data.sources[1] }} />
					<a href={data.sources[2]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `3. ` + data.sources[2] }} />
					<a href={data.sources[3]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `4. ` + data.sources[3] }} />
				</div>

      </div>
      */}

		</section>
)}

export default Main
